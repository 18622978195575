import React from 'react';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
// import baseService from '../../../lib/service/ServiceBase';
import './NotFount.less';

import Layout from '../components/Layout/LayoutIndex';
import { getHtmlTDK } from '../../../lib/utils/UtilsCommon';

/**
 * state
 */
interface IState {
	htmlTDK: {
		title: string;
	};
}

/**
 * NotFount page
 *
 * @class NotFount
 * @extends {React.Component<any>}
 */
@autobind
class NotFount extends React.Component<any, any> {
	constructor(props: any, context?: any) {
		super(props, context);

		const state: any = {
			htmlTDK: {
				title: '学兴趣就上兴趣岛——名师更多，课程更全',
				pageDescription: '兴趣岛兴趣学堂-名师更多，课程更全。摄影、瑜伽等30门主流课程21门学员数量全国领先。',
				pageKeyword: '兴趣,兴趣岛,兴趣岛兴趣学堂,兴趣学堂,千聊兴趣岛,兴趣岛-名师更多，课程更全,兴趣岛兴趣学堂-名师更多，课程更全,千聊,知识付费,内容付费,千聊特训营,成人教育',
			},
			leftTime: 3,
		};

		this.state = state;
	}

	/**
	 * 动态渲染数据放这里处理
	 *
	 * @static
	 * @param {*} { req }
	 * @returns {Promise<any>}
	 * @memberof NotFount
	 */
	public static async getInitialProps(context: any): Promise<any> {
		const { query, params } = context.req;
		// 注册接口
		// baseService.register(context);

		// await baseService.getHomeTags();

		context.res.statusCode = 404;

		return {};
	}

	/**
	 * 客户端调用获取数据
	 *
	 * @memberof NotFount
	 */
	public async componentDidMount(): Promise<any> {
		// // 注册接口
		// baseService.register(this.props);

		setTimeout(this.initTimer, 1000);

		return {};
	}

	/**
	 * 倒计时
	 */
	public initTimer(): void {
		let leftTime: number = this.state.leftTime - 1;
		if (leftTime > 0) {
			this.setState(
				{
					leftTime,
				},
				(): void => {
					setTimeout(this.initTimer, 1000);
				}
			);
		} else {
			(window as any).location.href = '/';
		}
	}

	/**
	 * 渲染页面
	 *
	 * @returns {*}
	 * @memberof NotFount
	 */
	public render(): any {
		return (
			<Layout {...getHtmlTDK(this.state.htmlTDK)} region="page-not-fount">
				<div className="p-layout-empty">
					<div>
						<h2>抱歉，该页面无法访问</h2>
						<p>
							页面链接可能已失效或被删除，{this.state.leftTime}秒后<a href="/">返回首页</a>
						</p>
					</div>
				</div>
			</Layout>
		);
	}
}

/**
 * mapStateToProps
 */
function mapStateToProps(state: any): any {
	return {};
}

const mapActionToProps: any = {};

export default connect(mapStateToProps, mapActionToProps)(NotFount);
